import { request } from "@/request/http";

export default {
    getPublicShowData: (data) => {
        return request("GET", `/index.php/backbase/getPublicShowData`,data);
    },

    getOpenDetail: (data) => {
        return request("GET", `/index.php/backcalendar/getOpenDetail`, data);
    },

    deleteStudentFromSchedule: (data) => {
        return request("POST", `/index.php/backcalendar/deleteStudentFromSchedule`, data);
    },

    createSignInorOutCode: (data) => {
        return request("GET", `/index.php/backcalendar/createSignInorOutCode`, data);
    },

    sendNotice: (data) => {
        return request("POST", `/index.php/backcalendar/sendNotice`, data);
    },

    addStudentToOpen: (data) => {
        return request("POST", `/index.php/backcalendar/addStudentToOpen`, data);
    },

    updateUserTotalScore: (data) => {
        return request("POST", `/index.php/backcalendar/updateUserTotalScore`, data);
    },

    allSignIn: (data) => {
        return request("GET", `/index.php/backcalendar/allSignIn`, data);
    },

    allSignOut: (data) => {
        return request("GET", `/index.php/backcalendar/allSignOut`, data);
    },

    getStudentToOpenList: (data) => {
        return request("POST", `/index.php/backcalendar/getStudentToOpenList`, data);
    },

    userSignIn: (data) => {
        return request("POST", `/index.php/backcalendar/userSignIn`, data);
    },

    userSignOut: (data) => {
        return request("POST", `/index.php/backcalendar/userSignOut`, data);
    },

    getGroupList: (data) => {
        return request("GET", `/index.php/group/getGroupList`, data);
    },

    addStudentToOpenByGroup: (data) => {
        return request("POST", `/index.php/backcalendar/addStudentToOpenByGroup`, data);
    },

    updateStudentReportScore: (data) => {
        return request("POST", `/index.php/backcalendar/updateStudentReportScore`, data);
    },

    showOptRecord: (data) => {
        return request("POST", `/index.php/backcalendar/showOptRecord`, data);
    },

    delOptRecord: (data) => {
        return request("POST", `/index.php/backcalendar/delOptRecord`, data);
    },

    getHandleList: (data) => {
        return request("POST", `/index.php/backcalendar/getHandleList`, data);
    },

    updateHandle: (data) => {
        return request("POST", `/index.php/backcalendar/updateHandle`, data);
    },

    deleteHandle: (data) => {
        return request("POST", `/index.php/backcalendar/deleteHandle`, data);
    },

    execDeductOpt: (data) => {
        return request("POST", `/index.php/backcalendar/execDeductOpt`, data);
    },

    getUserExpdataListByProjectid: (data) => {
        return request("POST", `/index.php/backcalendar/getUserExpdataListByProjectid`, data);
    },

    flushSigExpScore: (data) => {
        return request("POST", `/index.php/backcalendar/flushSigExpScore`, data);
    },
    
};