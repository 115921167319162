import{Message} from  'element-ui'
import { encryptCode,decryptCode } from "@/utils/function";
import { request_sign } from '@/utils/const';
import API from '@/api/teacher/calendar/studentList';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
  name: "StudentsList",
  components: {},
  inject:['reload'],
  data() {
    return {
      reportScore:'',
      search:'',
      studentslistData: [],
      dialogVisible: false,
      studentNumber: "",
      openID: "",
      SignInorOutVisible:false,
      signInorOutCodeTitle:"",
      SignInorOutCodeImgSrc:"",
      persionID:'',
      name:'',
      timenow:'',
      timeIntervel:'',
      sendNoticeDialogVisible:false,
      noticeValue:'',
      persion_id:'',
      seletStudents:[],
      addstudentList:[],
      deldialogVisible:false,
      delmanydialogVisible:false,
      allSigndialogVisible:false,
      allOutdialogVisible:false,
      delstudentID:'',
      addType:"1",
      group_id:[],
      underList:[],
      signInorOutCodeTime:'',
      studentOptListDialogVisible:false,
      studentOptListTitle:'',
      studentOptList:[],
      projectOptListDialogVisible:false,
      projectOptListTitle:'',
      project_id:0,
      student_id:0,
      projectOptList:[],

      // ==================================
      // edit by camellia 2023-03-23
      // 操作详情
      opt_des:'',
      opt_content:'',
      opt_reduct_score:0,
      opt_reduct_sign:0,
      opt_score:'',
      opt_sign:'',
      opt_id:0,
      studentOptReductDialogVisible:false,
      reductOKDialog:false,
      // =======================================================
      // 寻你实验数据弹窗
      studentExpDataList:[],
      studentExpDataVisiable:false,
      studentExpDataTitle:'',
    };
  },
  created() {
    //  获取学生列表数据
    this.getstudentslist();
  },
  methods: 
  {
    openFlushExpdataScoreDialog()
    {
      let self = this;
      // 删除同事请求
      self.$confirm(`是否确认刷新虚拟实验成绩？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let json = {
          open_id: self.openID,
          user_id: self.student_id
        };
        let str = encryptCode(json);
        let data = {sign:request_sign,params:str};
        nprogressStart();
        self.$Loading.show();
        API.flushSigExpScore(data)
            .then((result) => {
                if (result.code > 0) 
                {
                    this.$Tips({
                        // 消息提示内容
                        message: result.msg,
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'success',
                        displayTime: 1500
                    })
                    nprogressClose();
                    self.$Loading.hide();
                    self.studentExpDataVisiable = false;
                    self.getstudentslist();
                }
            }).catch(() => {
              nprogressClose();
              self.$Loading.hide();
          });
      })
    },

    getExpDataList(project_id,student_id,name,personal_id)
    {
      let self = this;
      self.student_id = student_id;
      let json = {
        project_id: project_id,
        student_id: student_id
      };
      self.studentExpDataTitle = name +' - '+personal_id;
      // 请求参数
      let str = encryptCode(json);
      let data = {sign:request_sign,params:str};
      nprogressStart();
      self.$Loading.show();
      API.getUserExpdataListByProjectid(data)
          .then((result) => {
            nprogressClose();
            self.$Loading.hide();
            self.studentExpDataVisiable = true;
            if (result.code > 0) 
            {
              self.studentExpDataList = result.data;
            }
          }).catch(() => {
            nprogressClose();
            self.$Loading.hide();
          })
    },

    studentReductOK()
    {
      let self = this;
      let json = {
        opt_id: self.opt_id,
        project_id: self.project_id,
        score: self.opt_reduct_score,
        student_id:self.student_id
      };
      // console.log(json);return;
      // 请求参数
      let str = encryptCode(json);
      let data = {sign:request_sign,params:str};
      nprogressStart();
      self.$Loading.show();
      API.execDeductOpt(data)
          .then((result) => {
            if (result.code > 0) 
            {
              self.reductOKDialog = false;
            }
            nprogressClose();
            self.$Loading.hide();
          }).catch(() => {
            nprogressClose();
            self.$Loading.hide();
          })
    },

    openStudentReductDialog(row)
    {
      var self = this;
      self.opt_id = row.id;
      self.opt_content = row.des;
      self.opt_reduct_sign = row.sign;
      self.opt_reduct_score = row.score;
      if(self.opt_reduct_sign == 0)
      {
        self.opt_reduct_score = row.score * -1;
      }
      self.reductOKDialog = true;
    },

    delOptRecord(row)
    {
      let self = this;
      // 请求参数
      let str = encryptCode({
        id: row.id,
      })
      let data = {sign:request_sign,params:str}
      // 删除同事请求
      self.$confirm(`确认要删除本条操作规则么?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
        nprogressStart();
        self.$Loading.show();
        API.deleteHandle(data)
            .then((result) => {
                if (result.code > 0) 
                {
                  self.getProjectOptList();
                  this.$Tips({
                      // 消息提示内容
                      message: result.msg,
                      // 消息提示类型（success-成功,warning-警告/失败）
                      messageType: 'success',
                      displayTime: 1500
                  })
                }
                nprogressClose();
                self.$Loading.hide();      
            })
      }).catch(() => {
        nprogressClose();
        self.$Loading.hide();      
      });
    },

    getOptRecordData(row)
    {
      var self = this;
      self.opt_des = row.des;
      self.opt_id = row.id;
      self.opt_score = row.score;
      self.opt_sign = row.sign;
    },

    optUpdate()
    {
      let self = this;
      // 请求参数
      let str = encryptCode({
        id: self.opt_id,
        project_id: self.project_id,
        des: self.opt_des,
        score: self.opt_score,
        optSign: self.opt_sign,
      })
      let data = {sign:request_sign,params:str};
      nprogressStart();
      self.$Loading.show();
      API.updateHandle(data)
          .then((result) => {
              if (result.code > 0) 
              {
                self.getProjectOptList();
                self.opt_des = '';
                self.opt_id = '';
                self.opt_score = '';
                self.opt_sign = '';
              }
              nprogressClose();
              self.$Loading.hide();
          })
    },

    getProjectOptList()
    {
      let self = this;
      // 请求参数
      let str = encryptCode({
        id: self.project_id
      })
      let data = {sign:request_sign,params:str};
      nprogressStart();
      self.$Loading.show();
      API.getHandleList(data)
          .then((result) => {
              if (result.code > 0) 
              {
                nprogressClose();
                self.$Loading.hide();
                self.projectOptList = result.data;
              }
          });
    },

    showUpdateOptDialog()
    {
      let self = this;
      // 打开弹窗
      self.projectOptListDialogVisible = true;
      self.projectOptListTitle = "设置";
      self.opt_des = '';
      self.opt_id = '';
      self.opt_score = '';
      self.opt_sign = '';
      // 请求操作列表数据
      self.getProjectOptList();
      
    },

    resetStudentOpt(row)
    {
      let self = this;
      // 请求参数
      let str = encryptCode({
        project_id: self.project_id,
        optRecord_id: row.id,
      })
      let data = {sign:request_sign,params:str}
      // 删除同事请求
      self.$confirm(`确认要撤销本条操作记录么?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
        nprogressStart();
        self.$Loading.show();
        API.delOptRecord(data)
            .then((result) => {
                if (result.code > 0) 
                {
                  self.getstudentslist();
                  self.showStudentOptDialog();
                  this.$Tips({
                      // 消息提示内容
                      message: result.msg,
                      // 消息提示类型（success-成功,warning-警告/失败）
                      messageType: 'success',
                      displayTime: 1500
                  })
                }
            })
      }).catch(() => {
        nprogressClose();
        self.$Loading.hide();      
      });
    },

    getStudentOptRecord()
    {
      let self = this;
      // 请求参数
      let str = encryptCode({
        id: self.project_id,
      })
      let data = { sign: request_sign, params: str }
      // startLoading();
      nprogressStart();
      self.$Loading.show();
      // 获取虚拟实验分类请求
      API.getHandleList(data)
          .then((result) => {
            nprogressClose();
            self.$Loading.hide();
            self.projectOptList = result.data;
          })
    },

    showOptRecord(project_id,student_id,name,personal_id)
    {
      let self = this;
      // self.studentOptListDialogVisible = true;
      self.studentOptReductDialogVisible = true;
      self.studentOptListTitle = name+'-'+personal_id;
      self.project_id = project_id;
      self.student_id = student_id;
      self.getStudentOptRecord();
    },

    showStudentOptDialog()
    {
      let self = this;
      // 请求参数
      let str = encryptCode({
        project_id: self.project_id,
        student_id: self.student_id,
      })
      let data = { sign: request_sign, params: str }
      nprogressStart();
      self.$Loading.show();
      API.showOptRecord(data)
          .then((result) => {
            nprogressClose();
            self.$Loading.hide();
            self.studentOptList = result.data;
            self.studentOptListDialogVisible = true;
          })
    },
 
    handleClick()
    {
      let self = this;
      if(self.addType == 2)
      {
        let data = {
          course_id : 0
        }
        API.getGroupList(data)
          .then((res)=>{
            if(res.code > 0)
            {
              self.underList = res.underList
            }
          })
      }
    },

    noticeDialogClose()
    {
      let self = this;
      self.noticeValue = ''
    },

    dialogVisibleClose()
    {
      let self = this;
      self.addstudentList = []
      self.studentNumber = ""
    },

    getStudentToOpenList()
    {
      let self = this;
      let data = {
        xuehao : self.studentNumber,
        open_id : self.openID
      }
      if(!self.studentNumber)
      {
        // Message.info('请输入学生学号')
        self.$Tips({
          // 消息提示内容
          message:'请输入学生学号',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType:'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button:[
            {
              type:1,
              text:'知道了'
            }
          ],
        })
        return
      }
      API.getStudentToOpenList(data)
        .then((res)=>{
          if(res.code > 0)
          {
            res.data.forEach((item,index)=>{
              item.index = index + 1
            })
            self.addstudentList = res.data
          }
        })
    },

    showallsignDialog()
    {
      let self = this
      self.allSigndialogVisible = true
    },

    showalloutDialog()
    {
      let self = this
      self.allOutdialogVisible = true
    },

    userSignIn(row)
    {
      let self = this;
      let data = {
        open_id : self.openID,
        student_id : row.userid
      }
      API.userSignIn(data)
          .then((res)=>{
            if(res.code>0)
            {
              // Message.success(res.msg)
              self.getstudentslist();
            }
            self.$Tips({
              // 消息提示内容
              message:res.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType:'success',
              displayTime:1500
            })
          })
    },

    userSignOut(row)
    {
      let self = this;
      let data = {
        open_id : self.openID,
        student_id : row.userid
      }
      API.userSignOut(data)
          .then((res)=>{
            if(res.code>0)
            {
              // Message.success(res.msg)
              self.getstudentslist();
            }
            self.$Tips({
              // 消息提示内容
              message:res.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType:'success',
              displayTime:1500
            })
          })
    },

    sendNotice()
    {
      try 
      {
        let self=this;
        let data={
          open_id:self.$route.query.id,
          persion_id:self.persion_id,
          noticeValue:self.noticeValue
        }
        API.sendNotice(data)
          .then((res)=>{
            if(res.code > 0 )
            {
              self.sendNoticeDialogVisible=false
              // Message.success(res.msg);
            }
            else
            {
              self.$Tips({
                // 消息提示内容
                message:res.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType:'success',
                displayTime:1500
              })
            }
            
          })
      } 
      catch (error) 
      {
          // Message.error('系统错误')
          self.$Tips({
            // 消息提示内容
            message:'系统错误',
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType:'warning',
            displayTime:1500
          })
      }
    },

    showNoticeDialog()
    {
      try 
      {
        let self=this;
        self.sendNoticeDialogVisible=true;
        API.getPublicShowData()
          .then((res)=>{
            if(res.code > 0 )
            {
              self.persion_id = res.array.personal_id
            }
            else
            {
              self.$Tips({
                // 消息提示内容
                message:res.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType:'success',
                displayTime:1500
              })
            }

          })
      } 
      catch (error) 
      {
        self.$Tips({
          // 消息提示内容
          message:'系统错误',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType:'warning',
          displayTime:1500
        })
      }
    },

    courseCode()
    {
      try 
      {
        let self= this;
        self.getTimeNow();   
        let data = {
          open_id : self.$route.query.id + "@@@scan_add"
        }
        nprogressStart();
        self.$Loading.show();
        API.createSignInorOutCode(data)
          .then(res=>{
            if(res)
            {
              nprogressClose();
              self.$Loading.hide();
              self.SignInorOutCodeImgSrc = res;
              self.signInorOutCodeTitle="扫码加入开放单元";
              self.SignInorOutVisible=true;
            }
          })
      } 
      catch (error) 
      {
        self.$Tips({
          // 消息提示内容
          message:'系统错误',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType:'warning',
          displayTime:1500
        })
      }
    },

    closeTimeIntervel()
    {
      var self=this;
      clearInterval(self.timeIntervel);
    },

    getTimeNow()
    {
      var self=this;
      self.timeIntervel=setInterval(() => {
        const time = new Date();
        const now=time.toLocaleString();
        self.timenow=now;
      }, 1000);
        
        
    },

    showSignOutCode()
    {
      try
      {
        let self= this;
        self.getTimeNow();   
        let data = {
          open_id : self.$route.query.id + "@@@sign_out"
        }
        nprogressStart();
        self.$Loading.show();
        API.createSignInorOutCode(data)
        .then(res=>{
          if(res)
          {
            nprogressClose();
            self.$Loading.hide();
            self.SignInorOutCodeImgSrc = res;
            self.signInorOutCodeTitle="扫码签出";
            self.SignInorOutVisible=true;
          }
        })
      } 
      catch (error) 
      {
        self.$Tips({
          // 消息提示内容
          message:'系统错误',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType:'warning',
          displayTime:1500
        })
      }
    },

    showSignInCode()
    {
      try
      {
        let self= this;
        self.getTimeNow();   
        let data = {
          open_id : self.$route.query.id + "@@@sign_in"
        }
        nprogressStart();
        self.$Loading.show();
        API.createSignInorOutCode(data)
        .then(res=>{
          if(res)
          {
            self.SignInorOutCodeImgSrc = res;
            self.signInorOutCodeTitle="扫码签到";
            self.SignInorOutVisible=true;
            nprogressClose();
            self.$Loading.hide();
          }
        })
      } 
      catch (error) 
      {
        self.$Tips({
          // 消息提示内容
          message:'系统错误',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType:'warning',
          displayTime:1500
        })
      }
    },

    allSignIn()
    {
      try 
      {
        let self=this;
        let data = {
          open_id : self.$route.query.id
        }
        API.allSignIn(data)
          .then(res=>{
            if(res.code > 0 )
            {
              self.allSigndialogVisible = false;
              // Message.success(res.msg);
              self.getstudentslist();
            }
            self.$Tips({
              // 消息提示内容
              message:res.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType:'success',
              displayTime:1500
            })
          })         
      } 
      catch (error) 
      {
        self.$Tips({
          // 消息提示内容
          message:'系统错误',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType:'warning',
          displayTime:1500
        })
      }
    },

    allSignOut()
    {
      let self=this;
      try 
      {
        let data = {
          open_id : self.$route.query.id
        }
        API.allSignOut(data)
        .then(res=>{
          if(res.code > 0 )
          {
            self.allOutdialogVisible = false
            // Message.success(res.msg);
            self.getstudentslist();
          }
          self.$Tips({
            // 消息提示内容
            message:res.msg,
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType:'success',
            displayTime:1500
          })
        })         
      } 
      catch (error) 
      {
        self.$Tips({
          // 消息提示内容
          message:'网络错误',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType:'warning',
          displayTime:1500
        })
      }
    },

    goReportPage(project_id, report_id, group_id, report_status,row) {
      var self = this;
      if (report_status == 4 || report_status == 5 || report_status == 3) 
      {
        /*window.open(
          "#/teacher/report/reportCorrection?project_id=" +
            project_id +
            "&report_id=" +
            report_id +
            "&group_id=" +
            group_id
        );//*/
        window.sessionStorage.setItem('href',window.location.href);
        self.$router.push({
          path: "/teacher/report/reportCorrection",
          query: {
            project_id: project_id,
            report_id: report_id,
            group_id: group_id,
            open_id:self.$route.query.id,
            name:self.$route.query.name,
            hideMenu : 1,
            type:1
          }
        }).then(()=>{
          self.reload()
        })
        ;
      }
    },

    addstudentbyID()
    {
      let self = this;
      let data = {
        xuehao: self.studentNumber + "",
        id: self.openID + "",
      }
      // startLoading()
      nprogressStart();
      self.$Loading.show();
      // 添加学生请求
      API.addStudentToOpen(data)
        .then((result)=>{
          if(result.code > 0 )
          {
            self.studentNumber = "";
            self.dialogVisible = false;
            self.getstudentslist();
          }
          // endLoading(startLoading())
          nprogressClose();
          self.$Loading.hide();
          self.$Tips({
            // 消息提示内容
            message:result.msg,
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType:'success',
            displayTime:1500
          })
        })
    },

    addstudentbyGroup()
    {
      let self = this;
      let data = {
        id:self.openID,
        group_id : self.group_id
      }
      // startLoading()
      // 添加学生请求
      API.addStudentToOpenByGroup(data)
        .then((result)=>{
          if(result.code > 0 )
          {
            self.group_id = [];
            self.dialogVisible = false;
            // endLoading(startLoading())
            self.$Tips({
              // 消息提示内容
              message:result.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType:'success',
              displayTime:1500
            })
            self.getstudentslist();
          }
          else
          {
            // endLoading(startLoading())
            self.$Tips({
              // 消息提示内容
              message:'系统错误',
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType:'warning',
              displayTime:1500
            })
          }
        })
    },

    addstudent() {
      let self = this;
      try 
      {
        // 请求参数
        // 通过学号添加学生到开放单元
        if(self.addType == 1)
        {
          self.addstudentbyID()
        }
        // 通过班级添加学生到开放单元
        else
        {
          self.addstudentbyGroup()
        }
      } 
      catch (error) 
      {
        // Message.error('系统错误')
        self.$Tips({
          // 消息提示内容
          message:'系统错误',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType:'warning',
          displayTime:1500
        })
      }
    },

    updateStudentReportScore(userID, projectID, report_score) 
    {
      try
      {
        let self = this;
        let data = {
          project_id: projectID,
          student_id: userID,
          report_score: Number(report_score),
        }
        API.updateStudentReportScore(data)
          .then((res)=>{
            if(res.code > 0 )
            {
              // Message.success(res.msg);
              self.getstudentslist();
            }
            self.$Tips({
              // 消息提示内容
              message:res.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType:'success',
              displayTime:1500
            })

          })
      } 
      catch (error) 
      {
        self.$Tips({
          // 消息提示内容
          message:'系统错误',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType:'warning',
          displayTime:1500
        })
      }
    },

    updateUserTotalScore(userID, projectID, finalScore) {
      try
      {
        let self = this;
        let data = {
          proj_id: projectID,
          user_id: userID,
          final_score: Number(finalScore) ,
        }
        API.updateUserTotalScore(data)
          .then((res)=>{
            if(res.code > 0 )
            {
              // Message.success(res.msg);
              self.getstudentslist();
            }
            self.$Tips({
              // 消息提示内容
              message:res.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType:'success',
              displayTime:1500
            })
          })
      } 
      catch (error) 
      {
        self.$Tips({
          // 消息提示内容
          message:'系统错误',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType:'warning',
          displayTime:1500
        })
      }
    },

    getstudentslist() {
      try 
      {
        let self = this;
        let data = {
          open_id : this.$route.query.id,
          search:this.search
        }
        nprogressStart();
        self.$Loading.show();
        API.getOpenDetail(data)
          .then((res)=>{
            nprogressClose();
            self.$Loading.hide();
            if(res.code > 0 )
            {
              if(!res.data[0].list[0].length == 0)
              {
                self.studentslistData = [];
              }
              else
              {

                self.studentslistData = res.data[0].list.flat();
                self.studentslistData?.forEach((item)=>{
                  item.fs = item.fs == "未采用"?0:item.fs
                })
              }
              self.openID = res.data[0].open_id;
              
              for (const item of self.studentslistData) {
                self.persionID+=(item.personal_id+" ");
                
              }
              self.name=res.data[0].name
              self.signInorOutCodeTime = res.data[0].time
              // endLoading(startLoading())
            }
            else
            {
              // endLoading(startLoading())
              self.$Tips({
                // 消息提示内容
                message:'系统错误',
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType:'warning',
                displayTime:1500
              })
            }
          })
      } 
      catch (error) 
      {
        self.$Tips({
          // 消息提示内容
          message:'系统错误',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType:'warning',
          displayTime:1500
        })
      }
    },

    showdelstudentDialog(row)
    {
      let self = this
      self.delstudentID = row.userid
      self.deldialogVisible = true
    },

    showdelmanystudentDialog()
    {
      let self = this
      if(self.seletStudents.length==0)
      {
        self.$Tips({
          // 消息提示内容
          message:'请至少选择一名学生',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType:'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button:[
            {
              type:1,
              text:'知道了'
            }
          ],
        })
          return;
      }
      self.delmanydialogVisible = true
    },

    deleteStudent(row)
    {
      try 
      {
        let self = this;
        let data={
            student_id:[self.delstudentID],
            open_id:self.openID
        }
        API.deleteStudentFromSchedule(data)
          .then((res)=>{
            if(res.code > 0 || res.code )
            {
              self.getstudentslist();
              self.deldialogVisible = false
              // Message.success(res.msg)
            }
            self.$Tips({
              // 消息提示内容
              message:res.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType:'success',
              displayTime:1500
            })
          })
      } 
      catch (error) 
      {
        self.$Tips({
          // 消息提示内容
          message:'系统错误',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType:'warning',
          displayTime:1500
        })

      }
    },

    deleteManyStudent()
    {
      try 
      {
        let self =this;
        if(self.seletStudents.length==0)
        {
          self.$Tips({
            // 消息提示内容
            message:'请至少选择一名学生',
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType:'success',
            displayTime:1500
          })
            return;
        }
        let data={
          student_id:self.seletStudents,
          open_id:self.$route.query.id
        }
        API.deleteStudentFromSchedule(data)
          .then((res)=>{
            if(res.code > 0 || res.code )
            {
              self.getstudentslist();
              self.delmanydialogVisible = false
              // Message.success(res.msg)
            }
            self.$Tips({
              // 消息提示内容
              message:res.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType:'success',
              displayTime:1500
            })
          })
      } 
      catch (error) 
      {
        self.$Tips({
          // 消息提示内容
          message:'系统错误',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType:'warning',
          displayTime:1500
        })
      }
    },

    selectDelStudent(selection)
    {
      let self = this;
      self.seletStudents=[];
      for (let item of selection) {
        self.seletStudents.push(item.userid)
      }
    },

    closeSubjectDialog()
    {
      var self= this;
      self.SignInorOutVisible = false;
      self.getstudentslist();
    },

    goToExamRecordPage(row)
    {
      let str = row.name +'-'+row.personal_id;
      window.open("#/teacher/ExamsList?project_id="+row.project_id+"&name="+str+"&hideMenu=1&ids="+row.userid);
    },
  },
};















